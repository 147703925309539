<template>
  <div class="with-nav-bar">
    <van-nav-bar
      title="管理中心"
      left-text="返回"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
    />
    <van-notice-bar
      color="#1989fa"
      background="#ecf9ff"
      left-icon="volume-o"
      scrollable
      >您好，欢迎进入管理中心！
    </van-notice-bar>
    <van-grid :column-num="2" clickable>
      <van-grid-item
        icon="wap-home-o"
        text="充电站管理"
        :to="{ name: 'Station' }"
      />
      <van-grid-item
        icon="hotel-o"
        text="充电桩管理"
        :to="{ name: 'Device' }"
      />
      <van-grid-item
        icon="orders-o"
        text="订单列表"
        :to="{ name: 'AgentChargingOrder' }"
      />
      <van-grid-item
        icon="chart-trending-o"
        text="统计报表"
        :to="{ name: 'AgentReport' }"
      />
      <van-grid-item
        icon="balance-o"
        text="分润钱包"
        :to="{ name: 'AgentWallet' }"
      />
      <van-grid-item
        icon="notes-o"
        text="我的资料"
        :to="{ name: 'AgentProfile' }"
      />
    </van-grid>
  </div>
</template>

<script>
import { NoticeBar, Grid, GridItem } from "vant";
export default {
  name: "Agent",
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [NoticeBar.name]: NoticeBar,
  },
};
</script>
